<template>
  <div>
    <CommonIndex
      :pageType="pageType"
      @showDetail="showDetail"
      :show-threshold="true"
      :showThreshold="false"
    >
      <span
        slot="expandButton"
        style="margin-right: 15px;"
        slot-scope="{ record }"
        @click="monitoringPoint(record)"
      >
        监测点
      </span>
    </CommonIndex>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import CommonIndex from '@/views/safety-device/component/common-index';
import { createDrawer, createModal } from '@triascloud/x-components';
import concreteDetail from './concrete-detail';
import { pageTypeEnum } from '@/views/safety-device/component/safety-utils';
import MonitoringPoint from './monitoring-point';

@Component({
  components: {
    CommonIndex,
  },
})
export default class ConcreteMonitor extends Vue {
  // 混凝土监测
  pageType = pageTypeEnum.concreteMonitor;

  showDetail(record) {
    this.modalDrawer = createDrawer(
      () => (
        <concreteDetail
          detailData={record}
          pageType={this.pageType}
          close={this.handleCloseModalDrawer}
        />
      ),
      {
        title: this.$t('safety.deviceDetail'),
        width: '720px',
        wrapClassName: 'deviceDetail',
      },
    );
  }
  monitoringPoint(record) {
    createModal(() => <MonitoringPoint deviceId={record.pkId} />, {
      title: '监测点配置',
      width: 800,
      maskClosable: false,
    });
  }
}
</script>

<style lang="less" module>
@import '../component/safety.less';
</style>
