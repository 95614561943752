<template>
  <div style="min-height: 400px;">
    <a-table
      :columns="columns"
      :data-source="tableData"
      style="height: 450px;"
      :scroll="{ y: 300 }"
      :pagination="false"
      :row-key="record => record.deviceId"
      :transformCellText="({ text }) => text || '-'"
      :loading="loading"
    >
      <template slot="type" slot-scope="text, record">
        <span v-if="!record.showUpdate">{{ typeEnum[text] }}</span>
        <a-select v-model="record.type" v-else style="width: 120px;">
          <a-select-option
            v-for="item in typeList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </template>
      <template slot="threshold" slot-scope="text, record">
        <span v-if="!record.showUpdate">{{ text ? text + '°C' : '-' }}</span>
        <div v-else>
          <a-input-number v-model="record.alarmValue" style="width: 120px;" />°C
        </div>
      </template>
      <template slot="action" slot-scope="record">
        <a @click.stop="update(record)">
          {{ record.showUpdate ? '保存' : '修改' }}
        </a>
      </template>
    </a-table>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  getPointConfig,
  putPointConfig,
} from '@/services/device-manage/setting';

@Component({
  components: {},
})
export default class CommonIndex extends Vue {
  @Prop({ type: String, default: '' }) deviceId;

  get columns() {
    return [
      {
        align: 'left',
        title: '监测点',
        dataIndex: 'subDeviceCode',
        ellipsis: true,
        width: 160,
      },
      {
        align: 'left',
        title: '监测点类型',
        dataIndex: 'type',
        ellipsis: true,
        scopedSlots: { customRender: 'type' },
      },
      {
        title: '报警阈值',
        dataIndex: 'alarmValue',
        ellipsis: true,
        scopedSlots: { customRender: 'threshold' },
      },
      {
        title: this.$t('common.operation'),
        key: 'operation',
        scopedSlots: { customRender: 'action' },
        width: 100,
      },
    ];
  }
  mounted() {
    this.getTableList();
  }
  typeEnum = {
    SURFACE_SKIN_TEMPERATURE: '表层温度',
    INNER_TEMPERATURE: '内层温度',
    ENVIRONMENT_TEMPERATURE: '环境温度',
    MIDDLE_UPPER_TEMPERATURE: '中上温度',
    MIDDLE_LOWER_TEMPERATURE: '中下温度',
  };
  get typeList() {
    return Object.keys(this.typeEnum).map(key => ({
      name: this.typeEnum[key],
      value: key,
    }));
  }
  loading = false;
  tableData = [];
  async getTableList() {
    this.loading = true;
    try {
      const params = {
        pkId: this.deviceId,
        current: 1,
        size: 500,
      };
      const { records } = await getPointConfig(params);
      this.tableData = records.map(item => {
        item.showUpdate = false;
        return item;
      });
    } finally {
      this.loading = false;
    }
  }
  async update(record) {
    if (!record.showUpdate) {
      record.showUpdate = true;
    } else {
      const params = {
        alarmValue: record.alarmValue,
        subDeviceCode: record.subDeviceCode,
        type: record.type,
        idxDeviceId: this.deviceId,
      };
      await putPointConfig(params);
      record.showUpdate = false;
      this.$message.success('保存成功！');
    }
  }
}
</script>

<style lang="less" module></style>
